import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';
import Job from '~components/jobs/Job';
import JobsHeroImage from '~images/jobs/jobs-hero.jpg';

import Container from '~components/Container';
import { jobsPage } from '~prismic/unfurl/job';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const useStyles = makeStyles(() => ({
  jobsTitle: {
    fontSize: '3.5rem',
    fontWeight: '500',
    paddingBottom: '20px',
    textAlign: 'center'
  },
  jobsSubTitle: {
    fontSize: '1.25rem',
    lineHeight: '2.25rem',
    textAlign: 'center'
  },
  jobsLists: {
    paddingTop: '80px',
    textAlign: 'left'
  },
  jobsListTitle: {
    fontSize: '1.25rem',
    fontWeight: '500'
  },
  media: {
    maxWidth: '100%'
  }
}));

const Jobs = ({ data }) => {
  const classes = useStyles();
  const jobGroupsByDepartment = jobsPage(data);
  const isNoJobs = jobGroupsByDepartment.length < 1;

  return (
    <Page>
      <SEO />
      <Box mt={10} component={Container}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={10}>
            <Box pb={2} textAlign="center">
              <img src={JobsHeroImage} alt="aleph beta jobs" className={classes.media} />
            </Box>

            <Box className={classes.jobsTitle}>Work With Us</Box>
            <Typography className={classes.jobsSubTitle} gutterBottom>
              Aleph Beta is a growing non-profit media company producing high quality animated Torah
              videos and podcasts for teenagers and adults. We believe that Torah is meant to be our
              guide, but we don’t always know exactly how. No one reads Bible like it’s a self-help
              manual, right? Aleph Beta’s mission is to read the text closely, and to discover, in
              surprising ways, how the Torah isn’t just a rulebook, but a guidebook, one that has
              profound wisdom that is relevant and meaningful in our everyday lives.
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={10}
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={2}
            className={classes.jobsLists}
          >
            {!isNoJobs &&
              jobGroupsByDepartment.map(([departmentCode, jobsInGroup]) => (
                <Grid key={departmentCode} item xs={12} sm={6} md={3} textAlign="center">
                  <Box className={classes.jobsListTitle} textAlign="center">
                    {departmentCode}
                  </Box>
                  {jobsInGroup.map(job => (
                    <Job
                      key={job.uid}
                      type={job.type}
                      location={job.location}
                      url={`/jobs/${job.uid}`}
                    >
                      {job.position}
                    </Job>
                  ))}
                </Grid>
              ))}
          </Grid>
          {isNoJobs && (
            <>
              <Box component={Typography} pb={10} variant="h4" textAlign="center">
                No openings at this time. Check back soon!
              </Box>

              <Box component={Typography} pb={10} textAlign="center">
                Have a question? Feel free to get in touch. Drop us an email at{' '}
                <a
                  href="mailto:HR@alephbeta.org?Subject=Job"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HR@alephbeta.org
                </a>
              </Box>
            </>
          )}
        </Grid>
      </Box>
    </Page>
  );
};

Jobs.propTypes = {
  data: PropTypes.shape().isRequired
};

export default withPrismicPreview(Jobs);

export const query = graphql`
  {
    allPrismicJobPage {
      edges {
        node {
          _previewable
          uid
          data {
            job_position
            department
            type
            location
          }
        }
      }
    }
  }
`;
