import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import classnames from 'classnames';
import Link from '~components/Link';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  centerText: {
    textAlign: 'center'
  },
  jobsListJobsJob: {
    padding: '16px 0',
    '& a': {
      color: '#438dce',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  jobsListJobsJobTitle: {
    fontSize: '1.3rem',
    fontWeight: '600',
    paddingBottom: '4px'
  },
  jobsListJobsJobInfo: {
    fontSize: '1.15rem',
    opacity: '0.8'
  }
}));

const Job = ({ children, url, type, location }) => {
  const classes = useStyles();

  return (
    <Box className={classnames(classes.centerText, classes.jobsListJobsJob)}>
      <Link to={url}>
        <Box className={classes.jobsListJobsJobTitle}>{children}</Box>
      </Link>
      <Box className={classes.jobsListJobsJobInfo}>{`${type} - ${location}`}</Box>
    </Box>
  );
};

Job.propTypes = {
  children: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
};

export default Job;
